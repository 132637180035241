import { Dialog } from '@mui/material'
import React from 'react'

import Loader from '../assets/Loader.svg';
import LibertyArmYellow from '../assets/LibertyArmYellow.svg';

const Loading = ({ openLoadingModal, handleCloseLoadingModal }) => {
    return (
        <Dialog
            maxWidth={'md'}
            open={openLoadingModal}
            onClose={handleCloseLoadingModal}
            BackdropProps={{
                style: {
                    backgroundColor: 'rgb(26, 20, 70, 0.9)'
                }
            }}
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    overflow: 'hidden',
                    position: 'unset'
                },
            }}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <img style={{
                    position: 'absolute',
                    
                    // width: '200px'
                }} src={Loader} />
                <img style={{
                    position: 'absolute',
                    
                    // width: '100px'
                }} src={LibertyArmYellow} />
            </div>

            
        </Dialog>
    )
}

export default Loading
