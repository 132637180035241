export const CAPTCHA_SITE_KEY           = process.env.REACT_APP_CAPTCHA_SITE_KEY
export const CAPTCHA_SECRET_KEY         = process.env.REACT_APP_CAPTCHA_SECRET_KEY

export const URL_ACCESS_TOKEN           = process.env.REACT_APP_URL_ACCESS_TOKEN
export const CLIENT_ID                  = process.env.REACT_APP_CLIENT_ID
export const CLIENT_SECRET              = process.env.REACT_APP_CLIENT_SECRET

export const URL_POLIZAS                = process.env.REACT_APP_URL_POLIZAS
export const URL_MANDATOS               = process.env.REACT_APP_URL_MANDATOS
export const URL_ACTIVAR_MANDATO        = process.env.REACT_APP_URL_ACTIVAR_MANDATO

export const URL_INICIO = '/activacion-mandato'
export const URL_CLIENTE = '/activacion-mandato/activar'
export const URL_SOLICITANTE = '/activacion-mandato/activar/solicitante'
export const CODES_PHONE_NUMBER = [
    '+56',
    '+569'
]