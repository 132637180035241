import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import ActivateMandate from './pages/ActivateMandate';
import InsuredDetail from './pages/InsuredDetail';
import ApplicantDetail from './pages/ApplicantDetail';
// import Error404 from './pages/errors/Error404';
// import ErrorMaintenance from './pages/errors/ErrorMaintenance';
import ErrorNotFound from './pages/errors/ErrorNotFound';
// nuevo agregado desde
import { useEffect } from 'react';
import { getToken } from './auth/getToken';


function App() {
  useEffect(() => {
    const generateToken = async() => {
      await getToken();
    }

    generateToken();
  }, []);
// nuevo agregado hasta
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/activacion-mandato" element={<ActivateMandate />} />
          <Route path="/activacion-mandato/activar" element={<InsuredDetail />} />
          <Route path="/activacion-mandato/activar/solicitante" element={<ApplicantDetail />} />
          <Route path="/" element={<Navigate to="/activacion-mandato" replace />} />
          <Route path="*" element={<ErrorNotFound />} />
        </Routes>
      </Router>
    </div>
  );
}
export default App;
