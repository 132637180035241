import React from 'react';
import { makeStyles } from '@mui/styles';
import InfoIconOutlined from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const useStyles = makeStyles({
    container: {
        // width: 'auto',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #C0BFC0',
        display: 'inline-flex',
        padding: '10px',
        borderRadius: '3px',
        // borderLeftColor: '#0061F5',
        borderLeftWidth: '6px',
        alignItems: 'center',
        // width: '60%'
    },
    infoText: {
        // color: '#0061F5',
        fontWeight: '700',
        fontSize: '16px',
        paddingLeft: '4px',
        paddingRight: '16px'
    },
    normalText: {
        fontSize: '16px'
    }
});

const AlertCustom = ({width, borderLeftColor, infoIcon, successIcon, color, text}) => {
    const styles = useStyles();

    return (
        <div className={styles.container} style={{borderLeftColor: borderLeftColor, width: width}}>
            { infoIcon 
                ? <InfoIconOutlined sx={{color:color}} />
                : null
            }

            { successIcon
                ? <CheckCircleOutlineIcon sx={{color:color}} />
                : null
            }
            <p className={styles.infoText} style={{color: color}}>{text}</p>
        </div>
    )
}

export default AlertCustom;
