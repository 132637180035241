import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Checkbox, MenuItem, TextField, Tooltip, Zoom } from '@mui/material';
import * as moment from 'moment';

import ButtonCustom from '../components/ButtonCustom';
import BreadcrumbsCustom from '../components/BreadcrumbsCustom';
import Banner from '../components/Banner';
import { 
    CODES_PHONE_NUMBER, 
    URL_INICIO, 
    URL_SOLICITANTE, 
    URL_ACTIVAR_MANDATO, 
} from '../config/Constants';
import { validateRut } from '@fdograph/rut-utilities';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalCustom from '../components/ModalCustom';
import IconInfo from '../assets/IconInfo.svg';
import IconSuccess from '../assets/IconSuccess.svg';
import IconWarning from '../assets/IconWarning.svg';
import Loading from '../components/Loading';
import { revalidateToken } from '../auth/getToken';

const useStyles = makeStyles({
    details: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '40px',
        maxWidth: '1224px',
        width: '100%',
        margin: '0 auto',
        marginTop: '32px',
        ["@media (max-width: 1280px)"]: {
            width: '96%'
        },
        ["@media (max-width: 450px)"]: {
            marginTop: '4rem'
        },
        ["@media (max-width: 400px)"]: {
            width: '94%'
        }
    },
    container: {
        background: '#F5F5F5',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    title: {
        letterSpacing: '0px',
        color: '#1A1446',
        textAlign: 'center',
        fontSize: '32px',
        fontWeight: '700'
    },
    pretitle: {
        color: '#333742',
        textAlign: 'center',
        letterSpacing: '0px',
        fontSize: '16px',
        fontWeight: '400'
    },
    warning: {
        color: '#28A3AF',
        letterSpacing: '0px',
        fontSize: '14px',
        fontWeight: '400'
    },
    alertBox: {
        background: '#D9E8FE',
        margin: '0 auto',
        padding: '0 20px',
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: '50px',
        marginBottom: '30px',

        '& > p': {
            color: '#343741',
            fontSize: '14px',
            fontWeight: '700'
        }
    },
    formData: {
        display: 'grid',
        gridTemplateColumns: '45% 45%',
        gap: '24px',
        maxWidth: '840px',
        width: '100%',
        margin: '0 auto',
        marginBottom: '30px',
        justifyContent: 'center',

        ["@media (max-width: 866px)"]: {
            gridTemplateColumns: '90%',
        },
    },
    dniContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    dniInfoText: {
        margin: '0',
        marginTop: '3px',
        textAlign: 'start',
        fontSize: '12px',
        color: '#808080'
    },
    cellphoneContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px'
    },
    codeField: {
        width: '35%'
    },
    phoneNumberField: {
        width: '100%'
    },
    checkInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
        marginBottom: '50px'
    },
    warningText: {
        color: '#f44336',
        margin: '0',
        textAlign: 'start',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '0px'
    },
    checkContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        justifyContent: 'start',
        gap: '10px',
    },
    checkText: {
        margin: '0',
        textAlign: 'start',
        color: '#333742',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '0px'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '16px',
        marginBottom: '48px',

        ["@media (max-width: 620px)"]: {
            flexDirection: 'column-reverse'
        },
    },
    customTooltip: {
        bgcolor: '#1A1446',
        fontSize: '16px',
        maxWidth: '235px',
        textAlign: 'center',
        fontWeight: '400',
        padding: '10px 20px',
        '& .MuiTooltip-arrow': {
            color: '#1A1446',
        },
    },
    
        "& .MuiFormHelperText-root": {
            margin: '0'
        }
    
});


const ApplicantDetail = () => {
    const styles = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    
    const items = [
        {
            name: 'Inicio',
            url: URL_INICIO
        },
        {
            name: 'Datos cliente',
            url: null
        },
        {
            name: 'Datos solicitante',
            url: URL_SOLICITANTE
        },
    ];

    // Formato válido de nombre (Solo ingreso de alfabeticos y espaciales, como acentos y dieresis)
    const validFormatName = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
    // Formato válido de rut: 12345678K
    const validFormatRut = /^[0-9]+[0-9K]$/;
    // Formato válido de email: dominio@email.com
    const validFormatEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // Formato valido de numero
    const validFormatNumber = /^[0-9\b]+$/;

    const [error, setError] = useState({})
    const [checkedAuth, setCheckedAuth] = useState(false);
    const [applicant, setApplicant] = useState({
        name: '',
        dni: '',
        email: '',
        code_number: '',
        phone_number: ''
    })

    const [openActivateModal, setOpenActivateModal] = useState(false);
    const [openLoadingModal, setOpenLoadingModal] =  useState(false);
    const [openQuitModal, setOpenQuitModal] = useState(false);

    const [openDirectAssociateSuccessModal, setOpenDirectAssociateSuccessModal] = useState(false);
    const [openDirectAssociateFailedModal, setOpenDirectAssociateFailedModal] = useState(false);


    useEffect(() => {
        if (!location.state) {
            navigate(URL_INICIO);
        }
    }, [])
    
    // ONCLICK BOTON -> VOLVER
    const onClickBack = () => {
        navigate(-1);
    }

    // ABRIR MODAL Y ONCLICK DE BOTON -> ACTIVAR AQUI
    const handleOpenActivateModal = () => {
        setOpenActivateModal(true);
    };

    const handleCloseActivateModal = () => {
        setOpenActivateModal(false);
    };

    // ABRIR Y CERRAR LOADING LIBERTY
    const handleOpenLoadingModal = () => {
        setOpenLoadingModal(true);
    };

    const handleCloseLoadingModal = (event, reason) => {
        // No se cierra modal en caso de hacer click fuera de este
        if (reason && reason == "backdropClick") 
        return;

        setOpenLoadingModal(false);
    };

    // ABRIR MODAL Y ONCLICK DE BOTON -> SALIR
    const handleOpenQuitModal = () => {
        setOpenQuitModal(true);
    };

    const handleCloseQuitModal = () => {
        setOpenQuitModal(false);
    };

    const handleOnClickQuitModal = () => {
        // Se limpia el estado y luego se redirige a pagina inicial
        navigate(location.pathname, { replace: true });
        navigate(URL_INICIO);
    };

    // ABRIR Y CERRAR MODAL DE ASOCIACION DIRECTA EXITOSO
    const handleOpenDirectAssociateSuccessModal = () => {
        setOpenDirectAssociateSuccessModal(true)
    };

    const handleCloseDirectAssociateSuccessModal = () => {
        setOpenDirectAssociateSuccessModal(false)
        // Se limpia el estado y luego se redirige a pagina inicial
        navigate(location.pathname, { replace: true });
        navigate(URL_INICIO);
    };

    // ABRIR Y CERRAR MODAL DE ASOCIACION DIRECTA ERRONEO
    const handleOpenDirectAssociateFailedModal = () => {
        setOpenDirectAssociateFailedModal(true)
    };

    const handleCloseDirectAssociateFailedModal = () => {
        setOpenDirectAssociateFailedModal(false)
        // Se limpia el estado y luego se redirige a pagina inicial
        navigate(location.pathname, { replace: true });
        navigate(URL_INICIO);
    };

    // FUNCION ONCLICK CUANDO APRIETA EN EL MODAL ACTIVAR AQUI -> SI ESTOY SEGURO
    const activateMandateToku = async() => {
        handleOpenLoadingModal();

        // Se valida token
        await revalidateToken();

        const clientData = location.state;

        const bodyToLambdaActivateMandate = {
            direct_redoc: clientData.useMandate,
            toku: {
                government_id: clientData.government_id,
                mail: clientData.new_email_entered 
                        ? clientData.new_email 
                        : clientData.original_email,
                name: clientData.name,
                new_email_entered: clientData.new_email_entered,
            },
            client: {
                insured_dni: clientData.government_id,
                insured_fullname: clientData.name,
                contracting_dni: null,
                contracting_fullname: null,
                policy_number: null,
                bank: null,
                account_number: null,
                email_original: clientData.original_email,
                email_new: clientData.new_email,
                date_entry: clientData.date_entry,
                ramo: null,
                payment_management: null,
                number_management: null,
                quota: null,
            },
            applicant: {
                user_logged: null,
                dni: applicant.dni,
                fullname: applicant.name,
                phone_number: `${applicant.code_number}${applicant.phone_number}`,
                email: applicant.email,
                policy_number: null,
                date_entry: moment().format('DD/MM/YYYY HH:mm:ss')
            },
        }

        const options = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${sessionStorage.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                bodyToLambdaActivateMandate
            )
        };

        const urlToBotonera = await fetch(URL_ACTIVAR_MANDATO, options)
        .then(response => response.json())
        .then(response => {
            handleCloseLoadingModal();
            
            return response?.data?.urlBotonera;
        })
        .catch(err => {
            handleCloseLoadingModal();
            console.error('ERROR FUNC ACTIVAR MANDATO: ', err)
        });

        return urlToBotonera;
    }

    const activateMandateDirect = async() => {
        handleOpenLoadingModal();

        // Se valida token
        await revalidateToken();

        const clientData = location.state;

        const bodyToLambdaActivateMandate = {
            direct_redoc: clientData.useMandate,
            to_fnc_direct_activate: {
                p_mandato: clientData.government_id,
                p_banco: clientData.mandate_bank,
                p_cod_banco: clientData.mandate_bank_code,
                p_fecha: clientData.date_entry,
                p_email: clientData.new_email_entered 
                    ? clientData.new_email
                    : clientData.original_email,
                p_nom_deudor: clientData.name,
                p_rut_deudor: clientData.government_id,
                p_ape_pat: null,
                p_ape_mat: null,
                p_num_cuenta: clientData.mandate_account_crypted,
                p_rut_liberty: clientData.government_id,
                p_nombre_liberty: clientData.name,
            },
            client: {
                insured_dni: clientData.government_id,
                insured_fullname: clientData.name,
                contracting_dni: clientData.government_id,
                contracting_fullname: clientData.name,
                policy_number: null,
                bank: clientData.mandate_bank,
                account_number: clientData.mandate_account,
                email_original: clientData.original_email,
                email_new: clientData.new_email,
                date_entry: clientData.date_entry,
                ramo: null,
                payment_management: null,
                number_management: null,
                quota: null,
            },
            applicant: {
                user_logged: null,
                dni: applicant.dni,
                fullname: applicant.name,
                phone_number: `${applicant.code_number}${applicant.phone_number}`,
                email: applicant.email,
                policy_number: null,
                date_entry: moment().format('DD/MM/YYYY HH:mm:ss')
            },
        }

        const options = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${sessionStorage.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                bodyToLambdaActivateMandate
            )
        };

        const mandateAssocResult = await fetch(URL_ACTIVAR_MANDATO, options)
        .then(response => response.json())
        .then(response => {
            handleCloseLoadingModal();

            return response?.data;
        })
        .catch(err => {
            handleCloseLoadingModal();
            console.error('ERROR FUNC ACTIVAR MANDATO: ', err)
        });

        return mandateAssocResult;
    }

    const handleOnClickActivateModal = async() => {
        setOpenActivateModal(false);

        if (location.state.useMandate) {
            const activateMandateDirectResult = await activateMandateDirect();

            if (activateMandateDirectResult?.fncProcess === 0 || activateMandateDirectResult?.fncProcess === 2 ) {
                handleOpenDirectAssociateSuccessModal();
            } else {
                handleOpenDirectAssociateFailedModal();
            }

        } else {
            // Se pega a lambda que tiene la logica de activar mandato y se obtiene URL para botonera
            const urlToBotoneraToku = await activateMandateToku();
    
            // Se limpia estado para que no pueda volver con datos guardados
            navigate(location.pathname, { replace: true });
            // Mandamos a botonera
            window.location.href = urlToBotoneraToku;
        }

    }

    const handleChangeCheckedAuth = (event) => {
        setCheckedAuth(event.target.checked);
    };

    const onChangeName = (e) => {
        // Se valida que no inicie espacios al inicio del texto, si es asi, se borra y se maneja el nombre vacio
        if (e.target.value.charAt(0) === ' ') {
            console.log('Se realizo un espacio al inicio del campo')
            setApplicant({
                ...applicant,
                name: e.target.value.slice(1, -1)
            })
        } else {
            setApplicant({
                ...applicant,
                name: e.target.value
            })
        }
    }

    const onChangeDni = (e) => {
        setApplicant({
            ...applicant,
            dni: e.target.value.toUpperCase()
        })
    }

    const onChangeEmail = (e) => {
        setApplicant({
            ...applicant,
            email: e.target.value
        })
    }

    const onChangeCodeNumber = (e) => {
        setApplicant({
            ...applicant,
            code_number: e.target.value
        })
    }

    const onChangePhoneNumber = (e) => {
        if (e.target.value === "" || validFormatNumber.test(e.target.value)) {
            setApplicant({
                ...applicant,
                phone_number: e.target.value
            })
        }
    }

    const validateForm = () => {
        setError({});

        const { name, dni, email, code_number, phone_number } = applicant;
        // Validaciones nombre
        // Que se haya ingresado el dato, considerándolo como obligatorio.
        // Que la cantidad de caracteres sea mínimo 2 y máximo 80.
        if(name.length === 0) {
            setError({
                field: 'name',
                msg: 'Campo obligatorio'
            });
            
            return;
        }
        if(name.length < 2) {
            setError({
                field: 'name',
                msg: 'Debe ingresar mínimo 2 caracteres'
            });
            
            return;
        }
        if(name.length > 80) {
            setError({
                field: 'name',
                msg: 'Debe ingresar máximo 80 caracteres'
            });
            
            return;
        }
        // Que no existan espacios iniciales al ingresar texto.
        if (name.charAt(0) === ' ') {
            setError({
                field: 'name',
                msg: 'No debe ingresar espacios al inicio'
            });
            
            return;
        }

        // Que no existan espacios al final texto.
        if (name.charAt(name.length - 1) === ' ') {
            setError({
                field: 'name',
                msg: 'No debe ingresar espacios al final'
            });
            
            return;
        }
        // Que el ingreso sea solo de caracteres alfabéticos y especiales tales como acentos y diéresis
        if (!name.match(validFormatName)) {
            setError({
                field: 'name',
                msg: 'Solo puede ingresar caracteres alfabéticos'
            });

            return;
        }

        // Validaciones rut
        // La cantidad de caracteres correspondan a mínimo 8 y máximo 9.
        if (dni.length === 0) {
            setError({
                field: 'dni',
                msg: 'Campo obligatorio'
            });

            return;
        }
        if (dni.length < 8) {
            setError({
                field: 'dni',
                msg: 'Debe ingresar mínimo 8 caracteres'
            });

            return;
        }
        if (dni.length > 9) {
            setError({
                field: 'dni',
                msg: 'Debe ingresar máximo 9 caracteres'
            });

            return;
        }
      
        if (!validateRut(dni)) {
            setError({
                field: 'dni',
                msg: 'Rut ingresado no es válido'
            });

            return;
        }
      
        if (!dni.match(validFormatRut)) {
            setError({
                field: 'dni',
                msg: 'Formato de Rut ingresado no es válido'
            });

            return;
        }

        // Validaciones email
        if(email.length === 0) {
            setError({
                field: 'email',
                msg: 'Campo obligatorio'
            });

            return;
        }

        if(email.length < 2) {
            setError({
                field: 'email',
                msg: 'Debe ingresar mínimo 2 caracteres'
            });

            return;
        }

        if(email.length > 80) {
            setError({
                field: 'email',
                msg: 'Debe ingresar máximo 80 caracteres'
            });

            return;
        }

        if(!email.match(validFormatEmail)) {
            setError({
                field: 'email',
                msg: 'Formato de email ingresado no es válido'
            });

            return;
        }

        // Validaciones codigo
        if(code_number.length === 0) {
            setError({
                field: 'code_number',
                msg: 'Campo obligatorio'
            });

            return;
        }

        // Validaciones telefono
        if(phone_number.length === 0) {
            setError({
                field: 'phone_number',
                msg: 'Campo obligatorio'
            });

            return;
        }

        if(phone_number.length > 9) {
            setError({
                field: 'phone_number',
                msg: 'Debe ingresar máximo 9 números'
            });

            return;
        }

        return true;
    }

    const onClickActivateHere = (e) => {
        e.preventDefault();

        const isValidForm = validateForm();

        if (isValidForm) handleOpenActivateModal();
    }

    return (
        <>
            <Banner />
            <div className={styles.details}>
                <BreadcrumbsCustom items={items}/>
            
                <h1 className={styles.title}>Datos del Solicitante</h1>
                <p className={styles.warning}>Los campos con (*) son obligatorios</p>

                <form onSubmit={onClickActivateHere} style={{ width: '100%' }}>
                    <div className={styles.formData}>
                        <TextField 
                            // required
                            label="Nombre del solicitante"
                            value={applicant.name}
                            name='name'
                            onChange={onChangeName}
                            error={error.field === 'name' ? true : false}
                            helperText={error.field === 'name' && error.msg}
                        />

                        <div className={styles.dniContainer}>
                            <TextField 
                                // required
                                label="Rut del solicitante"
                                value={applicant.dni}
                                name='dni'
                                onChange={onChangeDni}
                                error={error.field === 'dni' ? true : false}
                                helperText={error.field === 'dni' && error.msg}
                                // FormHelperTextProps={{ sx: {margin: '0'}
                                // }}
                            />
                            <p className={styles.dniInfoText}>Ejemplo: 12345678K</p>
                        </div>

                        <TextField 
                            // required
                            inputProps={{ maxLength: 80 }}
                            label="Email"
                            value={applicant.email}
                            name='email'
                            onChange={onChangeEmail}
                            error={error.field === 'email' ? true : false}
                            helperText={error.field === 'email' && error.msg}
                        />



                        
                        <div className={styles.cellphoneContainer}>
                            <TextField 
                                className={styles.codeField}
                                select
                                // required
                                label="Código"
                                value={applicant.code_number}
                                name='code_number'
                                onChange={onChangeCodeNumber}
                                error={error.field === 'code_number' ? true : false}
                                helperText={error.field === 'code_number' && error.msg}
                            >
                                {CODES_PHONE_NUMBER.map((code) => (
                                    <MenuItem key={code} value={code}>
                                    { code }
                                    </MenuItem >
                                ))}
                            </TextField>

                            <TextField 
                                className={styles.phoneNumberField}
                                // required
                                inputProps={{ maxLength: 9 }}
                                label="Teléfono de contacto"
                                value={applicant.phone_number}
                                name='phone_number'
                                onChange={onChangePhoneNumber}
                                error={error.field === 'phone_number' ? true : false}
                                helperText={error.field === 'phone_number' && error.msg}
                            />
                        </div>
                    </div>

                    <div className={styles.checkInfoContainer}>
                        <div className={styles.checkContainer} style={{marginBottom: '10px', gap: '5px'}}>
                            <InfoOutlinedIcon sx={{  color: '#f44336' }}/>
                            <p className={styles.warningText}>Ten en cuenta que para continuar debes hacer clic sobre el check de aceptación para iniciar el proceso de activación</p>
                        </div>
                        <div className={styles.checkContainer}>
                            <Checkbox checked={checkedAuth} onChange={handleChangeCheckedAuth} />
                            <p className={styles.checkText}>En mi calidad de tomador de la póliza, por el presente acto, autorizo expresamente la re-documentación de esta. <br/>En virtud de lo anterior, se modificará exclusivamente la forma de pago, autorizándose el cobro automático según el mandato otorgado para estos efectos.</p>
                        </div>
                    </div>

                    <div className={styles.buttonsContainer}>
                        <ButtonCustom 
                            id='activacion-mandato-paso-3-solicitante-volver'
                            type="button"
                            title='Volver'
                            disabled={false}
                            background={'#FFFFFF'}
                            hoverBorder={'1px solid #FFFFFF'}
                            color={'#1A1446'}
                            onClick={onClickBack}
                        />

                        <Tooltip 
                            arrow 
                            title='Al hacer clic estarás abandonando el proceso de activación de mandato' 
                            TransitionComponent={Zoom}
                            componentsProps={{ 
                                tooltip: { 
                                    sx: {
                                        bgcolor: '#1A1446',
                                        fontSize: '16px',
                                        maxWidth: '235px',
                                        textAlign: 'center',
                                        fontWeight: '400',
                                        padding: '10px 20px',
                                        '& .MuiTooltip-arrow': {
                                            color: '#1A1446',
                                        }
                                    } 
                                }
                            }}
                        >
                            <div style={{borderRadius: '50px'}}>
                                <ButtonCustom 
                                    id='activacion-mandato-paso-3-solicitante-cancelar'
                                    type="button"
                                    title='Cancelar'
                                    disabled={false}
                                    background={'#FFFFFF'}
                                    hoverBorder={'1px solid #FFFFFF'}
                                    color={'#1A1446'}
                                    onClick={handleOpenQuitModal}
                                />
                            </div>

                        </Tooltip>

                        <Tooltip 
                            arrow 
                            title='Continuar con el proceso de Activación' 
                            TransitionComponent={Zoom}
                            componentsProps={{ 
                                tooltip: { 
                                    sx: {
                                        bgcolor: '#1A1446',
                                        fontSize: '16px',
                                        maxWidth: '235px',
                                        textAlign: 'center',
                                        fontWeight: '400',
                                        padding: '10px 20px',
                                        '& .MuiTooltip-arrow': {
                                            color: '#1A1446',
                                        }
                                    } 
                                }
                            }}
                        >
                            <div style={{borderRadius: '50px'}}>
                                <ButtonCustom 
                                    id='activacion-mandato-paso-3-solicitante-activar'
                                    // type="input"
                                    title='ACTIVAR AQUI'
                                    disabled={!checkedAuth}
                                    background={'#99E5EA'}
                                    border={'1px solid #99E5EA'}
                                    hoverBorder={'1px solid #99E5EA'}
                                    color={'#1A1446'}
                                    hoverBg={'#06748C'}
                                />
                            </div>
                        </Tooltip>
                    </div>
                </form>

                <ModalCustom 
                    openModal={openActivateModal}
                    handleCloseModal={handleCloseActivateModal}
                    icon={IconInfo}
                    title={'¿Estás seguro de activar el mandato?'}
                    // descriptions={['No te preocupes, aquí estaremos para cuando decidas activar el mandato PAC']}
                    buttons={[
                        {
                            title: 'NO ESTOY SEGURO',
                            disabled: false,
                            background: '#FFFFFF',
                            color: '#1A1446',
                            hoverBorder: '1px solid #FFFFFF',
                            hoverBg: '',
                            hoverColor: '',
                            onClick: handleCloseActivateModal,
                        },
                        {
                            title: 'SI ESTOY SEGURO',
                            disabled: false,
                            background: '#99E5EA',
                            border: '1px solid #99E5EA',
                            color: '#1A1446',
                            hoverBorder: '1px solid #99E5EA',
                            hoverBg: '#06748C',
                            // hoverColor: '',
                            onClick: handleOnClickActivateModal,
                        }
                    ]}
                />

                <ModalCustom 
                    openModal={openDirectAssociateSuccessModal}
                    handleCloseModal={handleCloseDirectAssociateSuccessModal}
                    icon={IconSuccess}
                    title={'¡La póliza ha sido asociada con éxito!'}
                    // descriptions={['No te preocupes, aquí estaremos para cuando decidas activar el mandato PAC']}
                    buttons={[
                        {
                            title: 'Cerrar',
                            disabled: false,
                            background: '#99E5EA',
                            border: '1px solid #99E5EA',
                            color: '#1A1446',
                            hoverBorder: '1px solid #99E5EA',
                            hoverBg: '#06748C',
                            // hoverColor: '',
                            onClick: handleCloseDirectAssociateSuccessModal,
                        },
                    ]}
                />

                <ModalCustom 
                    openModal={openDirectAssociateFailedModal}
                    handleCloseModal={handleCloseDirectAssociateFailedModal}
                    icon={IconWarning}
                    title={'Lo sentimos'}
                    descriptions={['No fue posible asociar la póliza. \n Inténtalo nuevamente o comunícate con tu ejecutivo comercial.']}
                    buttons={[
                        {
                            title: 'Cerrar',
                            disabled: false,
                            background: '#99E5EA',
                            border: '1px solid #99E5EA',
                            color: '#1A1446',
                            hoverBorder: '1px solid #99E5EA',
                            hoverBg: '#06748C',
                            // hoverColor: '',
                            onClick: handleCloseDirectAssociateFailedModal,
                        },
                    ]}
                />

                <ModalCustom 
                    openModal={openQuitModal}
                    handleCloseModal={handleCloseQuitModal}
                    icon={IconInfo}
                    title={'¿Estás seguro que deseas salir?'}
                    descriptions={['No te preocupes, aquí estaremos para cuando decidas activar el mandato PAC']}
                    buttons={[
                        {
                            title: 'No, Volver',
                            disabled: false,
                            background: '#FFFFFF',
                            color: '#1A1446',
                            hoverBorder: '1px solid #FFFFFF',
                            hoverBg: '',
                            hoverColor: '',
                            onClick: handleCloseQuitModal,
                        },
                        {
                            title: 'Si, salir',
                            disabled: false,
                            background: '#99E5EA',
                            border: '1px solid #99E5EA',
                            color: '#1A1446',
                            hoverBorder: '1px solid #99E5EA',
                            hoverBg: '#06748C',
                            // hoverColor: '',
                            onClick: handleOnClickQuitModal,
                        }
                    ]}
                />

                <Loading 
                    openLoadingModal={openLoadingModal}
                    handleCloseLoadingModal={handleCloseLoadingModal}
                />
            </div>
        </>
    )
}

export default ApplicantDetail
