import React from 'react';
import { makeStyles } from '@mui/styles';
import HeroActivateMandate from '../assets/HeroActivateMandate.png';
import HeroActivateMandateMobile from '../assets/HeroActivateMandateMobile.png';

const useStyles = makeStyles({
    banner: {
        ["@media (max-width: 600px)"]: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative'
        },
        ["@media (max-width: 325px)"]: {
            paddingBottom: '50px'
        },
        // display: 'flex',
        // flexDirection: 'row'
    },
    bannerText: {
        position: 'absolute',
        left: '8%',
        paddingTop: '20px',
        width: '490px',
        textAlign: 'left',

        ["@media (max-width: 1360px)"]: {
            left: '6%'
        },

        ["@media (max-width: 1260px)"]: {
            left: '3%'
        },

        ["@media (max-width: 600px)"]: {
            width: '90%',
            position: 'absolute',
            top:'35%'
        },

        
            
        '& > h1': {
            color: '#1A1446',
            fontSize: '41px',
            fontWeight: '400',

            ["@media (max-width: 991px)"]: {
                // width: '100%',
                paddingRight: '24px'
            },

            ["@media (max-width: 670px)"]: {
                fontSize: '32px'
            },
        },
        '& > p': {
            color: '#00205B',
            fontSize: '16px',
            fontWeight: '400',
        },
    },
    bannerImage: {
        '& > img': {
            width: '100%',
            // maxHeight: '305px',
            height: '305px',
            // objectFit: 'initial',
            objectFit: 'cover',
            objectPosition: 'center'
        },

        ["@media (max-width: 600px)"]: {
            display: 'none'
        },
    },
    bannerImageMobile: {
        display: 'none',
        '& > img': {
            width: '100%',
            // maxHeight: '305px',
            height: '400px',
            // objectFit: 'initial',
            objectFit: 'cover',
            objectPosition: 'top'
        },

        ["@media (max-width: 600px)"]: {
            display: 'block'
        },
    }
});

const Banner = () => {
    const styles = useStyles();
    
    return (
        <div className={styles.banner}>
            <div className={styles.bannerImageMobile}>
                <img src={HeroActivateMandateMobile} />
            </div>
            <div className={styles.bannerText}>
                <h1>INSCRIBE EL MEDIO DE PAGO Y DESPREOCÚPATE</h1>
                <p>Inscribe y activa el mandato PAC de forma rápida y segura en un proceso 100% en línea</p>
            </div>
            <div className={styles.bannerImage}>
                <img src={HeroActivateMandate} />
            </div>
        </div>
    )
}

export default Banner
