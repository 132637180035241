import React from 'react';
import { makeStyles } from '@mui/styles';
import ButtonCustom from './ButtonCustom';

const useStyles = makeStyles({
    error: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '100px 50px 0px 50px',
    },
    icon: {
        width: '156px',
        height: '156px',
        objectFit: 'contain',
        objectPosition: 'center'
    },
    title: {
        color: '#1A1446',
        fontWeight: '300',
        fontSize: '40px',
        margin: '0'
    },
    descriptionBolded: {
        color: '#343741',
        fontWeight: '700',
        fontSize: '20px',
        margin: '0'
    },
    descriptionNormal: {
        color: '#343741',
        fontWeight: '400',
        fontSize: '16px'
    },
    descriptionHighlighted: {
        color: '#28A3AF',
        fontWeight: '400',
        fontSize: '14px',
        margin: '0',
        marginTop: '5px'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '13px',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '26px'
    },
    horizontalLine: {
        height: '1px',
        background: '#C0BFC0',
        marginTop: '60px',
        marginBottom: '200px'
    }
});

const ErrorCustom = ({ icon, title, descriptionBolded, descriptionNormal, descriptionHighlighted, buttons, horizontalLine}) => {
    const styles = useStyles();



    return (
        <>
            <div className={styles.error}>
                { icon ?
                        <picture>
                            <img src={icon} className={styles.icon} />
                        </picture>
                    :   null
                }

                { title ?
                        <h1 className={styles.title}>{title}</h1>
                    :   null
                }

                { descriptionBolded ?
                        <p className={styles.descriptionBolded}>{descriptionBolded}</p>
                    :   null
                }

                { descriptionNormal ?
                        <p className={styles.descriptionNormal}>{descriptionNormal}</p>
                    :   null
                }

                { descriptionHighlighted ?
                        <p className={styles.descriptionHighlighted}>{descriptionHighlighted}</p>
                    :   null
                }

                { buttons ?
                        <div className={styles.buttonsContainer}>
                            {buttons.length > 0 
                                ? buttons.map((button, index) => (
                                    <ButtonCustom 
                                        key={index}
                                        title={ button.title || '' } 
                                        disabled={ button.disabled || '' }
                                        background={ button.background || '' }
                                        color={ button.color || '' }
                                        border={ button.border || '' }
                                        borderRadius={ button.borderRadius || '' }
                                        hoverBg={ button.hoverBg || '' }
                                        hoverColor={ button.hoverColor || '' }
                                        boxShadow={ button.boxShadow || '' }
                                        boxShadowHover={ button.boxShadowHover || '' }
                                        textTransform={ button.textTransform || '' }
                                        onClick={ button.onClick || '' }
                                    />
                                ))
                                :
                                    null
                            }
                        </div> 
                    :   null
                }
            </div>
            {horizontalLine ?
                    <div className={styles.horizontalLine}></div>
                :   null
            }
        </>
    )
}

export default ErrorCustom;
