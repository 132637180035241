import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import LibertyLogo from '../assets/LibertyLogo.svg';
import LibertyLogo2 from '../assets/LibertyLogo2.png';
import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles({
    // CSS DE HEADER
    header: {
        backgroundColor: '#FFD000',
        padding: '1.8rem 0',
    },
    headerInner: {
        maxWidth: '1224px',
        width: '100%',
        margin: '0 auto',
        padding: '0',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        ["@media (max-width: 991px)"]: {
            flexFlow: 'row wrap',
        },
    },
    branding: {
        marginRight: '1rem',
        width: '100px',
        alignSelf: 'center',
        order: '0',
        paddingLeft: '0.8rem',
        ["@media (max-width: 991px)"]: {
            order: '2',
            alignSelf: 'flex-start',
        },
    },
    logoLink: {
        backgroundColor: 'transparent'
    },
    logoImage: {
        display: 'block',
        height: '49px',
        maxWidth: '100%',
        borderStyle: 'none',
    },
    burgerMenuIcon: {
        
        width: '0.875rem',
        display: 'inline-block',
        height: '1.25em',
        fontSize: '25px',
        width: '30px',
        verticalAlign: '-0.125em',
        marginRight: '2rem',
        order: '2',
        cursor: 'pointer',
        ["@media (min-width: 991px)"]: {
            display: 'none'
        },
    },
    headerMenuNav: {
        order: '1',
        width: '100%',
        listStyle: 'none',
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '0 0 1rem',
        padding: '0',

        ["@media (min-width: 768px)"]: {
            alignSelf: 'center',
            order: '1',
            width: '100%',
            listStyle: 'none',
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '0 0 1rem',
            padding: '0',
        },

        ["@media (min-width: 991px)"]: {
            width: 'auto',
            order: '2',
            margin: '0',
        },
    },
    headerMenuNavItem: {
        borderRight: '1px solid #1A1446',
        '&:last-child': {
            borderRight: 'none'
        }
    },
    headerMenuNavItemLink: {
        color: '#1A1446',
        textTransform: 'uppercase',
        textDecoration: 'none',
        padding: '10px',
        fontSize: '0.8rem',

        ["@media (min-width: 768px)"]: {
            fontSize: '0.875rem'
        }
    },
    // CSS DE NAV
    nav: {
        backgroundColor: '#1A1446',
        width: '100%',
        margin: '0 auto',
        padding: '0',
    },
    navMenu: {
        listStyle: 'none',
        margin: '0 auto',
        padding: '0',
        borderBottom: '1px solid',
        position: 'relative',
        zIndex: '10',
        borderBottom: 'none',
        display: 'flex',
        maxWidth: '1224px',
        ["@media (max-width: 991px)"]: {
            display: 'none'
        },
    },
    navItem: {
        display: 'block',
        borderTop: '1px solid ',
        flexWrap: 'wrap',
        position: 'relative',
        flexGrow: '1',
        '&:hover': {
            '& > ul': {
                '& > li': {
                    ["@media (min-width: 991px)"]: {
                        display: 'flex',
                        flexDirection: 'column'
                    },
                    ["@media (max-width: 990px)"]: {
                        display: 'block'
                    }
                },
            }
        },
        ["@media (min-width: 991px)"]: {
            height: '52px'
        }
    },
    navItemLink: {
        color: '#fff',
        display: 'flex',
        lineHeight: '1.4',
        padding: '1rem 1rem 0.7rem 1rem',
        textDecoration: 'none',
        fontWeight: '300',
        alignItems: 'center',
    },
    navSubMenu: {
        display: 'none',
        height: 'auto',
        overflow: 'hidden',
        position: 'relative',
        zIndex: '10',
        width: '100%',
        maxWidth: '1680px',
        borderTop: '0',
        marginTop: '0',
        padding: '0',
        ["@media (max-width: 991px)"]: {
            borderTop: '1px solid ',
        }
    },
    navItemSub: {
        display: 'none',
        listStyle: 'none',
        padding: '0',
        backgroundColor: '#1A1446',
        borderBottom: 'none',
        marginTop: '-1px',
        ["@media (max-width: 990px)"]: {
            borderTop: '1px solid ',
        }
    },
    navItemSubLink: {
        color: '#fff',
        display: 'flex',
        lineHeight: '1.4',
        padding: '10px 16px',
        textDecoration: 'none',
        fontWeight: '300',
        '&:hover': {
            backgroundColor: 'white',
            color: '#1A1446'
        },
        ["@media (max-width: 990px)"]: {
            paddingLeft: '3rem'
        }
    },
})

const menuItems = [
    {
        title: 'Nuestra Compañía',
        url: null,
        subItems: [
            {
                title: 'Quienes Somos',
                url: 'https://www.liberty.cl/nuestra-compania/quienes-somos'
            },
            {
                title: 'Organización',
                url: 'https://www.liberty.cl/nuestra-compania/organizacion'
            },
            {
                title: 'Gobierno Corporativo',
                url: 'https://www.liberty.cl/nuestra-compania/gobierno-corporativo'
            },
            {
                title: 'Publicaciones',
                url: 'https://www.liberty.cl/nuestra-compania/publicaciones'
            },
            {
                title: 'Sala de Prensa',
                url: 'https://www.liberty.cl/nuestra-compania/sala-de-prensa'
            }
        ]
    },
    {
        title: 'Nuestros Seguros',
        url: null,
        subItems: [
            {
                title: 'Seguros de Auto',
                url: 'https://www.liberty.cl/nuestros-seguros/seguro-automotriz'
            },
            {
                title: 'Seguro de Hogar',
                url: 'https://www.liberty.cl/nuestros-seguros/seguro-de-hogar'
            },
            {
                title: 'Seguros para Empresas',
                url: 'https://www.liberty.cl/nuestros-seguros/seguros-para-empresas'
            },
            {
                title: 'SOAP',
                url: 'https://www.liberty.cl/nuestros-seguros/seguro-automotriz/soap'
            },
            {
                title: 'Otros Seguros',
                url: 'https://www.liberty.cl/nuestros-seguros/otros-seguros'
            },
            {
                title: 'Consejos Liberty',
                url: 'https://www.liberty.cl/nuestros-seguros/liberty-te-aconseja'
            }
        ]
    },
    {
        title: 'Centro de Siniestros',
        url: null,
        subItems: [
            {
                title: 'Asistencia auto',
                url: 'https://www.liberty.cl/servicio-al-cliente/nuevos-clientes/asistencias'
            },
            {
                title: 'Automotriz',
                url: 'https://www.liberty.cl/centro-de-siniestros/automotriz'
            },
            {
                title: 'Hogar y otros',
                url: 'https://www.liberty.cl/centro-de-siniestros/centro-de-siniestro-de-hogar-y-otros'
            },
        ]
    },
    {
        title: 'Denuncio Web',
        url: null,
        subItems: [
            {
                title: 'Automotriz',
                url: 'https://denuncio.liberty.cl/'
            }
        ]
    },
    {
        title: 'Pago en Línea',
        url: 'https://www.liberty.cl/pago-en-linea',
        subItems: null
    },
    {
        title: 'Contáctanos',
        url: 'https://www.liberty.cl/contactanos',
        subItems: null
    }
]


const Header = () => {
    const styles = useStyles();
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });

    const [openMenuMobile, setOpenMenuMobile] = useState(false);
    const [openSubMenu, setOpenSubMenu] = useState(false);

    const handleOpenSubMenuOnMobile = () => {
        // if (isMobile) {
            setOpenSubMenu(prevState => !prevState);
        // }
    }

    return (
        <>
            <header className={styles.header}>
                <div className={styles.headerInner}>
                    <div className={styles.branding}>
                        <a className={styles.logoLink} href="https://www.liberty.cl/">
                            <img className={styles.logoImage} src={LibertyLogo} />
                        </a>
                    </div>
                    
                    <ul className={styles.headerMenuNav}>
                        <li className={styles.headerMenuNavItem}>
                            <a className={styles.headerMenuNavItemLink} href="https://www.liberty.cl/acceso-clientes.html">Clientes</a>
                        </li >
                            <li className={styles.headerMenuNavItem}>
                            <a className={styles.headerMenuNavItemLink} href="https://www.liberty.cl/acceso-corredores.html">Corredores</a>
                        </li>
                            <li className={styles.headerMenuNavItem}>
                            <a className={styles.headerMenuNavItemLink} href="https://www.liberty.cl/acceso-colaboradores.html">Colaboradores</a>
                        </li>
                    </ul>

                    <svg 
                        onClick={() => setOpenMenuMobile(prevState => !prevState)} 
                        className={styles.burgerMenuIcon}
                        aria-hidden="true" 
                        focusable="false" 
                        data-prefix="fas" 
                        data-icon="bars" 
                        role="img" 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 448 512"
                    >
                        <path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
                    </svg>
                    
                </div>
            </header>
            <nav className={styles.nav}>
                <ul className={styles.navMenu} style={{ display: openMenuMobile && isMobile ? 'block' : null }}>
                    { menuItems.map((item, indexItem) => (
                        <li className={styles.navItem} key={indexItem} >
                            { item.url 
                                ? 
                                    <a href={item.url} title={item.title} className={styles.navItemLink}>{item.title}</a>  
                                : 
                                    <span className={styles.navItemLink} title={item.title} onClick={handleOpenSubMenuOnMobile}>
                                        { item.title } <KeyboardArrowDownIcon fontSize="medium" />
                                    </span>
                            }

                            


                            { item.subItems ? 
                                <ul className={styles.navSubMenu} style={{ display: openSubMenu || !isMobile ? 'block' : 'none' }}>
                                    { item.subItems.map((subItem, indexSubItem) => 
                                        <li className={styles.navItemSub} key={indexSubItem}>
                                            <a href={subItem.url} title={subItem.title} className={styles.navItemSubLink}>{subItem.title}</a>
                                        </li>
                                    )}
                                    
                                </ul>    
                                : null
                            }
                                
                        </li>
                    ))}
                   
                </ul>
            </nav>
        </>
    )
}

export default Header
