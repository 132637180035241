import {
  CLIENT_ID,
  CLIENT_SECRET,
  URL_ACCESS_TOKEN,
} from "../config/Constants";

export const getToken = async () => {
  const details = {
    grant_type: "client_credentials",
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    scope: "",
  };

  let formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  const token = await fetch(URL_ACCESS_TOKEN, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: formBody,
  })
    .then((response) => response.json())
    .then((response) => {
      console.log("Se ejecuto correctamente getToken");
      sessionStorage.setItem("token", response.access_token);
      sessionStorage.setItem("tokenCreated", response.issued_at);
      sessionStorage.setItem("tokenExpired", response.expires_in);
    })
    .catch((error) => {
      console.error("ERROR getToken: ", error);
      return false;
    });

  return token;
};

export const checkTokenStillValid = async () => {
  const now = Date.now() / 1000;
  const tokenCreatedAt = Number(sessionStorage.getItem("tokenCreated")) / 1000;
  const tokenExpiresIn = Number(sessionStorage.getItem("tokenExpired"));

  const expiry = tokenCreatedAt + tokenExpiresIn;

  return now < expiry;
};

export const revalidateToken = async () => {
  const tokenIsValid = await checkTokenStillValid();

  if (!tokenIsValid) {
    await getToken();
  }
};
