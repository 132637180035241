import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Checkbox, Collapse, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip, Zoom } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useLocation, useNavigate } from 'react-router-dom';
import * as moment from 'moment';

import BreadcrumbsCustom from '../components/BreadcrumbsCustom';
import ButtonCustom from '../components/ButtonCustom';
import ModalCustom from '../components/ModalCustom';
import IconInfo from '../assets/IconInfo.svg';
import { URL_CLIENTE, URL_INICIO, URL_SOLICITANTE } from '../config/Constants';
import Banner from '../components/Banner';
import Loading from '../components/Loading';

const useStyles = makeStyles({
    details: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '40px',
        maxWidth: '1224px',
        width: '100%',
        margin: '0 auto',
        marginTop: '32px',
        ["@media (max-width: 1280px)"]: {
            width: '96%'
        },
        ["@media (max-width: 450px)"]: {
            marginTop: '4rem'
        },
        ["@media (max-width: 400px)"]: {
            width: '94%'
        }
    },
    container: {
        background: '#F5F5F5',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    title: {
        letterSpacing: '0px',
        color: '#1A1446',
        textAlign: 'center',
        fontSize: '32px',
        fontWeight: '700'
    },
    pretitle: {
        color: '#333742',
        textAlign: 'center',
        letterSpacing: '0px',
        fontSize: '16px',
        fontWeight: '400'
    },
    alertBox: {
        background: '#D9E8FE',
        margin: '0 auto',
        padding: '0 20px',
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: '50px',
        marginBottom: '30px',

        '& > p': {
            color: '#343741',
            fontSize: '14px',
            fontWeight: '700'
        }
    },
    formData: {
        display: 'grid',
        gridTemplateColumns: '45% 45%',
        gap: '24px',
        maxWidth: '840px',
        width: '100%',
        margin: '0 auto',
        marginBottom: '30px',
        justifyContent: 'center',

        ["@media (max-width: 866px)"]: {
            gridTemplateColumns: '90%',
        },
    },
    emailInputEditable: {
        display: 'flex',
        gap: '10px',
        '& .MuiTextField-root': {
            '& .MuiFormHelperText-root': {
                marginLeft: '0px',
                marginRight: '0px',
                color: '#808080',
                fontSize: '12px',
                fontWeight: '400',
                fontFamily: 'Roboto'
            }
        }
    },
    checkInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
        marginBottom: '50px'
    },
    warningText: {
        color: '#f44336',
        margin: '0',
        textAlign: 'start',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '0px'
    },
    checkContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        justifyContent: 'start',
        gap: '10px',
    },
    checkText: {
        margin: '0',
        textAlign: 'start',
        color: '#333742',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '0px'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '16px',
        marginBottom: '48px',

        ["@media (max-width: 620px)"]: {
            flexDirection: 'column-reverse'
        },
    },
    editIconContainer: {
        backgroundColor: '#FFD000',
        width: '50px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50px',
        cursor: 'pointer',
        position: 'relative',
        top: '8px',
        
        '&:hover': {
            transition: 'all 0.5s ease',
            backgroundColor: '#CBAC24',
            '& > svg': {
                transition: 'all 0.5s',
                backgroundColor: '#CBAC24',
            }
        },
    },
    customTooltip: {
        bgcolor: '#1A1446',
        fontSize: '16px',
        maxWidth: '235px',
        textAlign: 'center',
        fontWeight: '400',
        padding: '10px 20px',
        '& .MuiTooltip-arrow': {
            color: '#1A1446',
        },
    }
});


const InsuredDetail = () => {
    const styles = useStyles();
    const location = useLocation();
    const navigate = useNavigate();

    const items = [
        {
            name: 'Inicio',
            url: URL_INICIO
        },
        {
            name: 'Datos cliente',
            url: URL_CLIENTE
        },
    ];

    const [showNewEmail, setShowNewEmail] = useState(false);
    const [hideEmail, setHideEmail] = useState(false);
    const [error, setError] = useState({})
    const [payerDetails, setPayerDetails] = useState({
        dni: '',
        name: '',
        lastname: '',
        email: ''
    })

    const [newEmail, setNewEmail] = useState({
        email: '',
        email2: ''
    })

    useEffect(() => {
        if (location.state) {
            const { dni, name, lastname, email } = location.state;
    
            setPayerDetails({
                dni,
                name,
                lastname,
                email,
            });
    
            if (email === '' || email === null || email === undefined) {
                setShowNewEmail(true)
                setHideEmail(true)
            }
        } else {
            navigate(URL_INICIO);
        }
    }, [])

    // Arreglo de mandatos agregandole ID para darle la key a los items del select
    const mandatesWithId = location?.state?.mandates.map((item, index) => ({ ...item, ID: index + 1 })) || []

    const getMandateIndex = (id, mandates) => {
        for (let i = 0; i < mandates.length; i++) {
            if (mandates[i].ID === id) {
                return i;
            }
        }
        return "";
    }

    const [mandateSelected, setMandateSelected] = useState({
        ID: mandatesWithId[0]?.ID,
        ACC_NUMBER: mandatesWithId[0]?.ACC_NUMBER,
        ACC_NUMBER_C: mandatesWithId[0]?.ACC_NUMBER_C,
        BANK_CODE: mandatesWithId[0]?.BANK_CODE,
        BANK_NAME: mandatesWithId[0]?.BANK_NAME
    });

    const [checkedAuth, setCheckedAuth] = useState(false);

    const [openQuitModal, setOpenQuitModal] = useState(false);
    const [openLoadingModal, setOpenLoadingModal] =  useState(false);

    const onChangeAccountSelect = (event) => {
        setMandateSelected(event.target.value)
    }
    
    const handleChangeCheckedAuth = (event) => {
        setCheckedAuth(event.target.checked);
    };

    const handleOpenLoadingModal = () => {
        setOpenLoadingModal(true);
    };

    const handleCloseLoadingModal = (event, reason) => {
        // No se cierra modal en caso de hacer click fuera de este
        if (reason && reason == "backdropClick") 
        return;

        setOpenLoadingModal(false);
    };

    // ABRIR MODAL Y ONCLICK DE BOTON -> SALIR
    const handleOpenQuitModal = () => {
        setOpenQuitModal(true);
    };

    const handleCloseQuitModal = () => {
        setOpenQuitModal(false);
    };

    const handleOnClickQuitModal = () => {
        // Se limpia el estado y luego se redirige a pagina inicial
        navigate(location.pathname, { replace: true });
        navigate(URL_INICIO);
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const censorWord = (str, lettersToShow) => {
        let wordsToShow = '';
        for (let i = 0; i <= lettersToShow - 1; i++) {
            wordsToShow += str[i]
        }

        return wordsToShow + "*".repeat(str.length - lettersToShow);
    }

     
    const censorEmail = (email) => {
        if (email) {
            const emailSeparated = email.split("@");

            const emailBody = emailSeparated[0];
            const emailDomain = emailSeparated[1];
            const emailDomainAfterLastDot = emailDomain.substring(emailDomain.lastIndexOf('.') + 1);
            const emailDomainBeforeLastDot = emailDomain.slice(0, - (emailDomainAfterLastDot.length + 1));

            return censorWord(emailBody, 2) + "@" + censorWord(emailDomainBeforeLastDot, 1) + "." + emailDomainAfterLastDot;
        } else {
            console.log('No viene email - Email no valido')
            return null
        }
    }

    const handleShowHideEmail = () => {
        setShowNewEmail((prevState) => !prevState)
    }

    const validateNewEmail = () => {
        setError({});
        let isValid = true;

        if (showNewEmail) {

            if(newEmail.email.length === 0) {
                setError({
                    field: 'newEmail.email',
                    msg: 'Campo obligatorio'
                });

                isValid = false;
                return;
            }

            if(!validateEmail(newEmail.email) || newEmail.email.split('@')[0].length < 2 || newEmail.email.split('@')[0].length > 80) {
                setError({
                    field: 'newEmail.email',
                    msg: 'Email ingresado no es válido'
                });

                isValid = false;
                return;
            }

            if(newEmail.email2.length === 0) {
                setError({
                    field: 'newEmail.email2',
                    msg: 'Campo obligatorio'
                });

                isValid = false;
                return;
            }

            if(newEmail.email2 !== newEmail.email) {
                setError({
                    field: 'newEmail.email2',
                    msg: 'El email ingresado no es el mismo que el anterior'
                });

                isValid = false;
                return;
            }
        }

        setError({});

        return isValid;
    }

    const onSubmit = async(e) => {
        e.preventDefault();
        const isValid = validateNewEmail();

        if (isValid) {
            handleOpenLoadingModal();

            const clientDataFormatted = {
                government_id: payerDetails.dni,
                original_email: payerDetails.email,
                new_email: showNewEmail ? newEmail.email2 : null,
                name: `${payerDetails.name} ${payerDetails.lastname}`,
                new_email_entered: showNewEmail,
                date_entry: moment().format('DD/MM/YYYY HH:mm:ss'),
                useMandate: location.state.optionSelected.toUpperCase() === 'SI',
                mandate_account: mandateSelected.ACC_NUMBER,
                mandate_account_crypted: mandateSelected.ACC_NUMBER_C,
                mandate_bank: mandateSelected.BANK_NAME,
                mandate_bank_code: mandateSelected.BANK_CODE
            };

            const timerToLoading = setTimeout(() => {
                handleCloseLoadingModal();
                navigate(URL_SOLICITANTE, {state: clientDataFormatted})
            }, 1000);

            return () => clearTimeout(timerToLoading);
        }
    }

    return (
        <>
            <Banner />
            <div className={styles.details}>
                <BreadcrumbsCustom items={items}/>
            
                <h1 className={styles.title}>Datos del Cliente</h1>
                
                <p className={styles.pretitle}>Datos asociados al RUT ingresado</p>

                <div className={styles.alertBox}>
                    <InfoOutlinedIcon sx={{ paddingRight: '5px', color: '#0061F5' }}/><p>Si los datos relacionados con el RUT no coinciden, por favor contáctate con tu corredor</p>
                </div>

                <div className={styles.formData}>
                    <TextField 
                        disabled
                        label="Rut"
                        value={payerDetails.dni}
                    />

                    <TextField 
                        disabled
                        label="Nombres"
                        value={payerDetails.name}
                    />

                    <TextField 
                        disabled
                        label="Apellidos"
                        value={payerDetails.lastname}
                    />

                    {hideEmail ?
                        (<div></div>)
                        : (
                            <div className={styles.emailInputEditable}>
                                <TextField 
                                    InputProps={{
                                        readOnly: payerDetails.email.length === 0 ? false : true,
                                    }}
                                    sx={{width: '100%', fontStyle: 'italic'}}
                                    label="Email"
                                    value={payerDetails.email ? censorEmail(payerDetails.email) : ''}
                                    helperText={'Puedes actualizar la información del email.'}
                                />
                                <Tooltip 
                                    arrow 
                                    title="Editar email" 
                                    TransitionComponent={Zoom}
                                    componentsProps={{ 
                                        tooltip: { 
                                            sx: {
                                                bgcolor: '#1A1446',
                                                fontSize: '16px',
                                                maxWidth: '235px',
                                                textAlign: 'center',
                                                fontWeight: '400',
                                                padding: '10px 20px',
                                                '& .MuiTooltip-arrow': {
                                                    color: '#1A1446',
                                                }
                                            } 
                                        }
                                    }}
                                >
                                    <div className={styles.editIconContainer}>
                                        <EditIcon onClick={handleShowHideEmail} fontSize={'medium'} sx={{backgroundColor: '#FFD000',color: '#1A1446'}} />
                                    </div>
                                </Tooltip>
                            </div>
                        )
                    }
                    
                    
                    <Collapse in={showNewEmail}>
                        <TextField
                            required
                            sx={{width: '100%'}}
                            InputLabelProps={{ shrink: true }}
                            label="Email"
                            placeholder="Ingrese el nuevo email a registrar..."
                            onChange={(e) => setNewEmail({
                                email: e.target.value, 
                                email2: newEmail.email2
                            })}
                            value={newEmail.email.trim()}
                            error={error.field === 'newEmail.email' ? true : false}
                            helperText={error.field === 'newEmail.email' && error.msg}
                        /> 
                    </Collapse>

                    <Collapse in={showNewEmail}>
                        <TextField 
                            required
                            sx={{width: '100%'}}
                            InputLabelProps={{ shrink: true }}
                            label="Confirmación email"
                            placeholder="Confirme el nuevo email ingresado..."
                            onChange={(e) => setNewEmail({
                                email: newEmail.email, 
                                email2: e.target.value
                            })}
                            value={newEmail.email2.trim()}
                            error={error.field === 'newEmail.email2' ? true : false}
                            helperText={error.field === 'newEmail.email2' && error.msg}
                        />
                    </Collapse>

                    { location?.state?.optionSelected.toUpperCase() === 'SI' ?
                        (
                            <FormControl fullWidth style={ !showNewEmail ? {textAlign: 'left', position: 'relative', top: '-30px'} : { textAlign: 'left' }}>
                                <InputLabel id="demo-simple-select-label">Número de cuenta corriente</InputLabel>
                                <Select
                                    defaultValue={""}
                                    value={mandatesWithId[getMandateIndex(mandateSelected.ID, mandatesWithId)]}
                                    label="Número de cuenta corriente"
                                    onChange={onChangeAccountSelect}
                                >
                                    {mandatesWithId.map((mandate) => (
                                        <MenuItem key={mandate.ID} value={mandate}>
                                            {mandate.ACC_NUMBER}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )
                        : null
                    }

                    { location?.state?.optionSelected.toUpperCase() === 'SI' ?
                        (
                            <TextField 
                                style={ !showNewEmail ? {textAlign: 'left', position: 'relative', top: '-30px'} : { textAlign: 'left' }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    readOnly: true,
                                    
                                }}
                                label="Banco"
                                value={mandateSelected.BANK_NAME}
                            />
                        )
                        : null
                    }
                </div>

                <div className={styles.checkInfoContainer}>
                    <div className={styles.checkContainer} style={{marginBottom: '10px', gap: '5px'}}>
                        <InfoOutlinedIcon sx={{  color: '#f44336' }}/>
                        <p className={styles.warningText}>Ten en cuenta que para continuar debes hacer clic sobre el check de aceptación para iniciar el proceso de activación</p>
                    </div>
                    <div className={styles.checkContainer}>
                        <Checkbox checked={checkedAuth} onChange={handleChangeCheckedAuth} />
                        <p className={styles.checkText}>Autorizo, en mi calidad de titular o representante del titular de los datos personales, al almacenamiento y tratamiento de estos por Liberty Compañía de Seguros Generales S.A. u otras entidades filiares o subsidiarias del mismo grupo empresarial, según lo establecido en la Ley N° 19.628 o aquella que la reemplace. <br/>Este dato será utilizado exclusivamente para los fines de gestiones o comunicaciones de cobranza.</p>
                    </div>
                </div>

                <div className={styles.buttonsContainer}>
                    <ButtonCustom 
                        id='activacion-mandato-paso-2-cliente-salir'
                        // type="input"
                        title={'Salir'} 
                        disabled={false}
                        background={'#FFFFFF'}
                        hoverBorder={'1px solid #FFFFFF'}
                        color={'#1A1446'}
                        onClick={handleOpenQuitModal}
                    />
                    <Tooltip 
                        arrow 
                        title='Continuar con el proceso de Activación' 
                        TransitionComponent={Zoom}
                        componentsProps={{ 
                            tooltip: { 
                                sx: {
                                    bgcolor: '#1A1446',
                                    fontSize: '16px',
                                    maxWidth: '235px',
                                    textAlign: 'center',
                                    fontWeight: '400',
                                    padding: '10px 20px',
                                    '& .MuiTooltip-arrow': {
                                        color: '#1A1446',
                                    }
                                } 
                            }
                        }}
                    >
                        <div style={{borderRadius: '50px'}}>
                            <ButtonCustom 
                                id='activacion-mandato-paso-2-cliente-siguiente'
                                // type="input"
                                title={'Continuar'} 
                                disabled={!checkedAuth}
                                background={'#99E5EA'}
                                border={'1px solid #99E5EA'}
                                hoverBorder={'1px solid #99E5EA'}
                                color={'#1A1446'}
                                hoverBg={'#06748C'}
                                onClick={onSubmit} 
                            />
                        </div>
                    </Tooltip>
                </div>
           
                <ModalCustom 
                    openModal={openQuitModal}
                    handleCloseModal={handleCloseQuitModal}
                    icon={IconInfo}
                    title={'¿Estás seguro que deseas salir?'}
                    descriptions={['No te preocupes, aquí estaremos para cuando decidas activar el mandato PAC']}
                    buttons={[
                        {
                            title: 'No, Volver',
                            disabled: false,
                            background: '#FFFFFF',
                            color: '#1A1446',
                            hoverBorder: '1px solid #FFFFFF',
                            hoverBg: '',
                            hoverColor: '',
                            onClick: handleCloseQuitModal,
                        },
                        {
                            title: 'Si, salir',
                            disabled: false,
                            background: '#99E5EA',
                            border: '1px solid #99E5EA',
                            color: '#1A1446',
                            hoverBorder: '1px solid #99E5EA',
                            hoverBg: '#06748C',
                            // hoverColor: '',
                            onClick: handleOnClickQuitModal,
                        }
                    ]}
                />

                <Loading 
                    openLoadingModal={openLoadingModal}
                    handleCloseLoadingModal={handleCloseLoadingModal}
                />
            </div>
        </>
    )
}

export default InsuredDetail;
